
import { mergeMap as _observableMergeMap, catchError as _observableCatch, map, tap, switchMap } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf, throwError } from 'rxjs';
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from './service-proxies';

@Injectable()
export class TradeTokensService {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }

    availableTokens(): Observable<IListResultDto<IAvailableTradeToken>> {
        let url_ = `${this.baseUrl}/api/services/app/Token/GetAll`;

        return this.http.get(url_).pipe(
            map((x: any) => x.result as IListResultDto<IAvailableTradeToken>)
            );
    }

    availableMarkets(): Observable<IListResultDto<IAvailableTokenMarket>> {
        let url_ = `${this.baseUrl}/api/services/app/Token/GetMarkets`;

        return this.http.get(url_).pipe(
            map((x: any) => x.result as IListResultDto<IAvailableTokenMarket>)
            );
    }

    coinsByMarket(body: ICoinsByMarketArg): Observable<IListResultDto<IAvailableTokenByMarket>> {
        let url_ = `${this.baseUrl}/api/services/app/Token/CoinsByMarket`;

        return this.http.post<IListResultDto<IAvailableTokenByMarket>>(url_,body).pipe(
            map((x: any) => x.result as IListResultDto<IAvailableTokenByMarket>)
            );
    }

    addMarket(body: ICreateTradeMarket): Observable<ICreateTradeMarketResult>{
        let url_ = `${this.baseUrl}/api/services/app/Token/CreateTradeMarket`;
        
        return this.http.post<ICreateTradeMarketResult>(url_, body).pipe(
            map((res: any) => res.result as ICreateTradeMarketResult)
            );
    }

    removeToken(coinShort: string,id: string): Observable<any>{
        let url_ = `${this.baseUrl}/api/services/app/Token/RemoveTradeToken?coin=${coinShort}&id=${id}`;

        return this.http.delete(url_);
    }

    actionHistories(page: number, pageSize: number): Observable<IListResultDto<IActionHistoryList>> {
        let url_ = `${this.baseUrl}/api/services/app/ActionHistory/GetAll?page=${page}&pageSize=${pageSize}`;

        return this.http.get(url_).pipe(
            map((x: any) => x.result as IListResultDto<IActionHistoryList>)
            );
    }

    getNotificationsAll(page: number, pageSize: number): Observable<IListResultDto<INotificationList>>{
        let url_ = `${this.baseUrl}/api/services/app/Notification/GetAll?page=${page}&pageSize=${pageSize}`;

        return this.http.get(url_).pipe(
            map((x: any) => x.result as IListResultDto<INotificationList>)
            );
    }
    getNotificationsUnread(): Observable<IListResultDto<INotificationList>>{
        let url_ = `${this.baseUrl}/api/services/app/Notification/GetUnread`;

        return this.http.get(url_).pipe(
            map((x: any) => x.result as IListResultDto<INotificationList>)
            );
    }
    markNotificationsRead(): Observable<any>{
        let url_ = `${this.baseUrl}/api/services/app/Notification/ClearAll`;

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                "Accept": "text/plain"
            })
        };

        return this.http.request("post", url_, options_).pipe();
    }


    tradingTokens(): Observable<IListResultDto<ITradingToken>> {
        let url_ = `${this.baseUrl}/api/services/app/TokenTrading/GetAll`;

        return this.http.get(url_).pipe(
            map((x: any) => x.result as IListResultDto<ITradingToken>)
            );
    }
    
    tradingTokenAvailableMarkets(coin: string): Observable<ICoinListResultDto<TradingTokenMarket>> {
        let url_ = `${this.baseUrl}/api/services/app/TokenTrading/GetAvailableMarkets?coin=${coin}`;

        return this.http.get(url_).pipe(
            map((x: any) => x.result as ICoinListResultDto<TradingTokenMarket>)
            );
    }

    addTokenTrades(body: ITradingTokenMarketCreate[]): Observable<ResponseDto>{
        let url_ = `${this.baseUrl}/api/services/app/TokenTrading/CreateTokenTrading`;

        return this.http.post<ResponseDto>(url_, body).pipe(map((res: any) => res.result as ResponseDto));
    }
    
    stopTrading(id: string): Observable<any>{
        let url_ = `${this.baseUrl}/api/services/app/TokenTrading/StopTokenTrading?id=${id}`;
        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                "Accept": "text/plain"
            })
        };
        return this.http.post(url_, null, options_);
    }   
    
    getTradingPlan(): Observable<number> {
        let url_ = `${this.baseUrl}/api/services/app/TradePlan/GetActual`;

        return this.http.get<number>(url_).pipe(map((x: any) => x.result as number));
    }

    setAdminTradingPlan(userId: number, level: number): Observable<any> {
        let url_ = `${this.baseUrl}/api/services/app/TradePlan/SetActual`;
        return this.http.post<any>(url_,{ userId: userId, level: level });
    }

    cancelAdminTradingPlan(userId: number): Observable<any>{
        let url_ = `${this.baseUrl}/api/services/app/TradePlan/CancelSubscription?userId=${userId}`;
        return this.http.post<any>(url_, undefined);
    }
    
    getTradingPlanUrls(): Observable<ITradingPlanUrls> {
        let url_ = `${this.baseUrl}/api/services/app/TradePlan/GetUrls`;
        return this.http.get<ITradingPlanUrls>(url_).pipe(map((x: any) => x.result as ITradingPlanUrls));
    }

    setTradingPlan(level: number): Observable<any>{
        let url_ = `${this.baseUrl}/api/services/app/TradePlan/SetActual`;
        const content_ = JSON.stringify({ level: level });

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                "Accept": "text/plain"
            })
        };

        return this.http.request("post", url_, options_).pipe();
    }

    getDashboardData(): Observable<IDashboardChart[]>{

        let url_ = `${this.baseUrl}/api/services/app/Dashboard/GetChartData`;
        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                "Accept": "text/plain"
            })
        };
        return  this.http.get(url_).pipe(
            map((x: any) => x.result as IDashboardChart[])
            );
    }
}

export interface IListResultDto<T>{
    items: T[]
}
export interface ICoinListResultDto<T> extends IListResultDto<T>, IAvailableTokenByMarket{
    tradePlanLevel: number;
}
export interface IAvailableTokenMarket{
    id: string;
    market: string;
    marketIcon: string;    
    amount: number;
    balance: number;
}
export interface IAvailableTokenByMarket{
    coin: string;
    coinShort: string;
    coinIcon: string;
    amount: number;
    active: boolean;
}
export interface IActionHistoryList{
    date: Date;
    coin: string;
    coinShort: string;
    coinIcon: string;
    description: string;
    quantity: number;
}
export interface INotificationList{
    date: string;
    message: string;
    isRead: boolean;
}
export interface IAvailableTradeToken {
    coin: string;
    coinShort: string;
    coinIcon: string;
    canStartTrade: boolean;
    markets: IAvailableTokenMarket[],
    amount: number;
    balance: number;
    price24hValue: number;
    price24hPercent: number;
    price24hPercentValue: number;
    profitLosssValue: number;
    profitLosssPercent: number;
    profitLosssPercentValue: number;
    tag: string;
    showDetail: boolean;
}
export interface ITradingPlanUrls{    
    standartLinks: ITradingPlanUrlItems[];    
    proLinks: ITradingPlanUrlItems[];
    vipLinks: ITradingPlanUrlItems[];
}
export interface ITradingPlanUrlItems{
    link: string;
    month: number;
    price: number;
}
export interface ITradingToken {
    id: string;
    coin: string;
    coinShort: string;
    coinIcon: string;
    market: string;
    marketIcon: string;
    pair: string;
    amouint: number;
    balance: number;
    price24hValue: number;
    volume24hValue: number;
    volume24hPercent: number;
}
export class TradingTokenMarket implements ITradingTokenMarketBase, ITradingTokenMarketCreate{
    pair: string;
    spread: number;
    depthMinus: number;
    volumeMinus: number;
    depthPlus: number;
    volumePlus: number;
    
    coin: string;
    coinShort: string;
    coinIcon: string;
    market: string;
    amount: number;
    price: number;
    balance: number;
    marketIcon: string;
    marketId: string;
    pairs: string[];
    networks: string[];
    show: boolean;
    isValid: boolean;
}
export interface ITradingTokenMarketBase {
    pairs: string[];
}
export interface ITradingTokenMarketCreate {
    coin: string;
    coinShort: string;
    coinIcon: string;
    market: string;
    marketIcon: string;
    pair: string;
    spread: number;
    depthMinus: number;
    depthPlus: number;
}

export class TradingTokenMarketCreate implements ITradingTokenMarketCreate{
    pair: string;
    spread: number;
    depthMinus: number;
    volumeMinus: number;
    depthPlus: number;
    volumePlus: number;
    coin: string;
    coinShort: string;
    coinIcon: string;
    market: string;
    marketIcon: string;
    marketId: string;

    constructor(coin: string, market: string,marketId: string, pair: string, spread: number, depthMinus: number, volumeMinus: number, depthPlus: number, volumePlus: number){
        this.coin = coin;
        this.market = market;
        this.marketId = marketId;
        this.pair = pair;
        this.spread = spread;
        this.depthMinus = depthMinus;
        this.volumeMinus = volumeMinus;
        this.depthPlus = depthPlus;
        this.volumePlus = volumePlus;
    }
}

export interface IDashboardChart{
    coinShortName: string;
    coinName: string;
    coinIcon: string;
    available: number;
    availableUsd: number;
    spendStableCoin: number;
    spendComission: number;
    spendFuture1d: number;
    spendFuture7d: number;
    spendFuture30d: number;
    spendFutureCurrent: number;
    spendCoin: number;
    price: number;
    volume: number;
    dataCoinmarketcap: IDashboardChartItem[],
    dataSerotonin: IDashboardChartItem[],
    dataStable: IDashboardChartItem[],
    dataStableUsd: IDashboardChartItem[]
}
export interface IDashboardChartItem{
    date: Date;
    value: number;
    price: number;
}

export interface IResponseDto{
    message: string;
    success: boolean;
}
export class ResponseDto implements IResponseDto{
    message: string;
    success: boolean;

    constructor(data?: IResponseDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}
export interface ICreateTradeMarket{
    market: string;
    tag: string;
    apiKey: string;
    apiSecret: string;
    apiPass: string;
    coins: ICreateTradeTokenCoin[]
}
export interface ICoinsByMarketArg{
    market: string;
    apiKey: string;
    apiSecret: string;
    apiPass: string;
}
export interface ICreateTradeMarketResult{
    id: string;
}
export class CreateTradeMarket implements ICreateTradeMarket{
    market: string;
    tag: string;
    apiKey: string;
    apiSecret: string;
    apiPass: string;
    coins: ICreateTradeTokenCoin[];

    constructor(data?: ICreateTradeMarket) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}
export interface ICreateTradeTokenCoin{
    name: string;
    amount: number;
}
export class CreateTradeTokenCoin implements ICreateTradeTokenCoin{
    name: string;
    amount: number;

    constructor(data?: ICreateTradeTokenCoin) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}