import {
  Component,
  Input,
  Injector,
  Renderer2,
  ElementRef,
  OnInit,
  AfterViewInit
} from '@angular/core';
import { AbstractControl, NgModel } from '@angular/forms';
import { AppComponentBase } from '@shared/app-component-base';
import { AbpValidationError } from './abp-validation.api';

@Component({
  selector: 'abp-validation-summary',
  templateUrl: './abp-validation.summary.component.html'
})
export class AbpValidationSummaryComponent extends AppComponentBase implements OnInit, AfterViewInit  {

  defaultValidationErrors: Partial<AbpValidationError>[] = [
    { name: 'required', localizationKey: 'ThisFieldIsRequired' },
    {
      name: 'minlength',
      localizationKey: 'PleaseEnterAtLeastNCharacter',
      propertyKey: 'requiredLength',
    },
    {
      name: 'maxlength',
      localizationKey: 'PleaseEnterNoMoreThanNCharacter',
      propertyKey: 'requiredLength',
    },
    {
      name: 'min',
      localizationKey: 'PleaseEnterMoreOrEqualThenNNumber',
      propertyKey: 'min',
    },
    {
      name: 'max',
      localizationKey: 'PleaseEnterLessOrEqualThenNNumber',
      propertyKey: 'max',
    },
    {
      name: 'email',
      localizationKey: 'InvalidEmailAddress',
    },
    {
      name: 'pattern',
      localizationKey: 'InvalidPattern',
      propertyKey: 'requiredPattern',
    },
    {
      name: 'validateEqual',
      localizationKey: 'PairsDoNotMatch',
    },
  ];
  validationErrors = <AbpValidationError[]>this.defaultValidationErrors;

  @Input() control: NgModel;
  @Input() controlEl: ElementRef;
  @Input() highliteInvalid: boolean = true;

  constructor(injector: Injector, public _renderer: Renderer2) {
    super(injector);
  }
  @Input() set customValidationErrors(val: AbpValidationError[]) {
    if (val && val.length > 0) {
      const defaults = this.defaultValidationErrors.filter(
        (defaultValidationError) =>
          !val.find(
            (customValidationError) =>
              customValidationError.name === defaultValidationError.name
          )
      );
      this.validationErrors = <AbpValidationError[]>[...defaults, ...val];
    }
  }

  ngOnInit() {
    if (this.controlEl) {
      this.control.valueChanges.subscribe(() => {
        if (
          this.control.valid &&
          (this.control.dirty || this.control.touched)
        ) {
          this._renderer.removeClass(this.controlEl, 'is-invalid');
        }else if(this.highliteInvalid){
          this._renderer.addClass(this.controlEl, 'is-invalid');
        }
      });
    }
  }
  
  ngAfterViewInit(): void {
    if(this.control){
      if (
        this.control.valid &&
        (this.control.dirty || this.control.touched)
      ) {
        this._renderer.removeClass(this.controlEl, 'is-invalid');
      }
      else if(this.highliteInvalid){    
        const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;
        if(validator && validator.required && !this.control.model) {
          this._renderer.addClass(this.controlEl, 'is-invalid');
        }else{
          this._renderer.removeClass(this.controlEl, 'is-invalid');
        }
      }
    }
  }

  getValidationErrorMessage(error: AbpValidationError): string {
    if (this.controlEl) {
      this._renderer.addClass(this.controlEl, 'is-invalid');
    }

    const propertyValue = this.control.errors[error.name][error.propertyKey];
    return !!propertyValue
      ? this.l(error.localizationKey, propertyValue)
      : this.l(error.localizationKey);
  }
}
